export const INCREMENT = "INCREMENT";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_CURRENTTAB = "UPDATE_CURRENTTAB";
export const UPDATE_BREADCRUMB = "UPDATE_BREADCRUMB";
export const SEARCH_CLICK = "SEARCH_CLICK";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

export function increment() {
  return {
    type: INCREMENT,
  };
}

export function update_token(token: string) {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
}

export function updateIsLoading(status: boolean) {
  return {
    type: UPDATE_IS_LOADING,
    payload: status,
  };
}

export function updateCurrentTab(tab: string) {
  return {
    type: UPDATE_CURRENTTAB,
    payload: tab,
  };
}

export function updateBreadcrumb(crumbs: any) {
  return {
    type: UPDATE_BREADCRUMB,
    payload: crumbs,
  };
}

export function updateSearchClick(data: string) {
  return {
    type: SEARCH_CLICK,
    payload: data,
  };
}

export function updateUserRole(role: string) {
  return {
    type: UPDATE_USER_ROLE,
    payload: role,
  };
}
