import {
  INCREMENT,
  UPDATE_TOKEN,
  UPDATE_IS_LOADING,
  UPDATE_CURRENTTAB,
  UPDATE_BREADCRUMB,
  SEARCH_CLICK,
  UPDATE_USER_ROLE,
} from "../action";

export interface initaialStateProps {
  count: number;
  token: string;
  isLoading: boolean;
  userRole: string;
  currentTab: string;
  crumbs: object;
  searchClick: string;
}

const initialState: initaialStateProps = {
  count: 0,
  token: "No token",
  isLoading: true,
  userRole: "",
  currentTab: "Home",
  crumbs: [{ path: "/dashboard", name: "Dashboard" }],
  searchClick: "contractSearchClicked",
};

export default function Reducer(state = initialState, action: any) {
  switch (action.type) {
    case INCREMENT:
      return { ...state, count: state.count + 1 };
    case UPDATE_TOKEN:
      return { ...state, token: action.payload };
    case UPDATE_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case UPDATE_CURRENTTAB:
      return { ...state, currentTab: action.payload };
    case UPDATE_BREADCRUMB:
      return { ...state, crumbs: action.payload };
    case SEARCH_CLICK:
      return { ...state, searchClick: action.payload };
    case UPDATE_USER_ROLE:
      return { ...state, userRole: action.payload };
    default:
      return state;
  }
}
